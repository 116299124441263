<template>
<el-dialog v-model="visible" title="选择查询条件" :before-close="close" custom-class="ex-search-dialog">
  <el-form :model="form" ref="formRef">
    <el-form-item prop="t_project_id" label="按所属项目发放：">
      <el-select v-model="form.t_project_id">
        <el-option v-for="(item, index) in proOption" clearable :key="index" :label="item.txt_project_name" :value="item.t_project_id">
          <div v-if="item.txt_project_name.length<14">{{ item.txt_project_name }}</div>
          <el-popover v-else placement="top-start" trigger="hover" :content="item.txt_project_name">
            <template #reference>
              <div>{{ item.txt_project_name.slice(0, 14) + "..." }}</div>
            </template>
          </el-popover>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item prop="txt_filter_number" label="按受试者筛选号发放：">
      <el-input v-model="form.txt_filter_number" placeholder="受试者筛选号" clearable>
        <template #prepend>
          <i class="iconfont icon-sousuo" />
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="dateRange" label="按审核通过日期发放：">
      <el-date-picker value-format="YYYY-MM-DD" v-model="form.dateRange" clearable type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
    </el-form-item>
  </el-form>
  <div class="exsd-hint" v-if="hintShow">* 请至少选择一个查询条件</div>
  <template #footer>
    <div class="dialog-footer">
      <el-button type="info" @click="reset()">重 置</el-button>
      <el-button type="primary" @click="goListPage()">确 认</el-button>
    </div>
  </template>
  <ex-detail v-if="detail.show" :propData="detail.data" @close="(e)=>{detail.show=false;if(e==true){close(e)}}" />
</el-dialog>
</template>

<script>
// 费用管理 - 发放 - 选择查询条件
import { computed, onMounted, reactive, toRefs } from 'vue';
import { getProjectList } from "api/apis.js";
import { ElMessage } from 'element-plus';
import exDetail from "./exDetail.vue";
export default {
  components: {
    exDetail,
  },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      form: {
        t_project_id: "",
        txt_filter_number: "",
        dateRange: []
      },
      hintShow: false,
      detail: {
        show: false,
        data: {}
      },
      proOption: [],
      proNull: computed(() => (state.form.t_project_id || "") == ""),
      tFNumberNull: computed(() => (state.form.txt_filter_number || "") == ""),
      dateRangeNull: computed(() => (state.form.dateRange || []).length == 0)
    })

    onMounted(() => {
      getProOptions();
    })

    // 前往 列表 dialog
    const goListPage = () => {
      // 必填校验
      let values = [state.proNull, state.tFNumberNull, state.dateRangeNull];
      // true = 空 false=非空
      if (values.findIndex(t => t == false) != -1) {
        state.hintShow = false;
        state.detail.data = state.form;
        if (!state.proNull) {
          let index = state.proOption.findIndex(t => t.t_project_id == state.form.t_project_id);
          state.detail.data.proName = state.proOption[index].txt_project_name;
        }
        // 显示 大列表 dialog
        state.detail.show = true;
      } else {
        state.hintShow = true;
      }
    };

    // 获取项目列表
    const getProOptions = () => {
      getProjectList({
        pages: 1,
        pageSize: 999
      }).then(response => {
        if (response.code == 200) {
          state.proOption = response.data;
        } else {
          state.proOption = [];
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      })
    };

    const close = (type) => {
      ctx.emit("close", type);
    };

    // 重置表单
    const reset = () => {
      state.form = {
        t_project_id: "",
        txt_filter_number: "",
        dateRange: []
      }
    };

    return {
      ...toRefs(state),
      close,
      getProOptions,
      reset,
      goListPage
    }
  }
}
</script>

<style lang="scss">
.ex-search-dialog {
  width: 36%;
  height: 50%;

  .el-dialog__body {
    padding: 30px 42px;

    .el-form {
      .el-form-item {
        margin-bottom: 24px;

        .el-form-item__label {
          width: 30%;
          text-align: left;
          font-size: 15px;
        }

        .el-form-item__content {
          .el-select {
            width: 100%;
          }
        }
      }
    }

    .exsd-hint {
      margin-top: 14px;
      color: #e7a861;
    }
  }

  .dialog-footer {
    .el-button {
      border-radius: 16px;

      &:last-child {
        border-color: #8a88c3;
        background-color: #8a88c3;
      }
    }
  }
}
</style>
