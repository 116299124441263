<template>
<div id="page" class="release-record-index">
  <el-row>
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-form-item prop="txt_distribution_request_no">
        <el-input v-model="form.txt_distribution_request_no" placeholder="业务参考号">
          <template #prepend>
            <i class="iconfont icon-sousuo" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="dateRange">
        <el-date-picker value-format="YYYY-MM-DD" v-model="form.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
      </el-form-item>
      <el-form-item prop="status" label="状态">
        <el-select v-model="form.status">
          <el-option v-for="(item, index) in statusOption" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList()">搜索</el-button>
        <el-button type="info" @click="reset()">重置</el-button>
      </el-form-item>
    </el-form>
  </el-row>
  <el-table :data="table.data" v-loading="table.loading" header-cell-class-name="table-cell">
    <el-table-column prop="txt_distribution_request_no" label="业务参考号" align="center" />
    <el-table-column prop="dec_request_success_amount" label="实发金额/应发金额（元）" align="center">
      <template #default="scope">
        <span :class="(scope.row.dec_request_success_amount||0) != scope.row.dec_request_total_amount ? 'text-red' : ''">{{ scope.row.dec_request_success_amount||"0.00" }}</span> / {{ scope.row.dec_request_total_amount }}
      </template>
    </el-table-column>
    <el-table-column prop="int_request_success_number" label="实发笔数/应发笔数" align="center">
      <template #default="scope">
        <span :class="(scope.row.int_request_success_number||0) != scope.row.int_request_total_number ? 'text-red' : ''">{{ scope.row.int_request_success_number||0 }}</span> / {{ scope.row.int_request_total_number }}
      </template>
    </el-table-column>
    <el-table-column prop="dt_distribution_request" label="发放汇总时间" align="center">
      <template #default="scope">{{scope.row.dt_distribution_request||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="txt_status" label="发放状态" align="center">
      <template #default="scope">
        <template v-if="scope.row.txt_status==1"><span class="text-red">异常</span></template>
        <template v-else-if="scope.row.txt_status==2">发放中</template>
        <template v-else-if="scope.row.txt_status==3">发放失败</template>
        <template v-else-if="scope.row.txt_status==4">已发放</template>
      </template>
    </el-table-column>
    <el-table-column prop="dt_distribute_approval" label="发放成功时间" align="center">
      <template #default="scope">{{scope.row.dt_distribute_approval||"- -"}}</template>
    </el-table-column>
    <el-table-column label="操作" align="center" class-name="table-option">
      <template #default="scope">
        <div class="table-icon" v-if="scope.row.txt_status==2" @click="handleReload(scope.row)">状态刷新</div>
        <div class="table-icon" v-if="scope.row.txt_status==1" @click="handleReissue(scope.row)">再次发放</div>
        <div class="table-icon" @click="handleDetail(scope.row)">查看详情</div>
      </template>
    </el-table-column>
  </el-table>
  <pagination ref="pageRef" :total="table.total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
  <rc-detail :propData="detail.data" v-if="detail.show" @close="()=>{detail.show=false;detail.data={}}" />
</div>
</template>

<script>
// 项目运营管理 发放记录
import { useStore } from "vuex";
import { Pagination } from "components";
import RcDetail from "./comp/rcDetail.vue";
import { ElMessage, ElMessageBox } from 'element-plus';
import { reactive, toRefs, computed, onMounted, ref } from "vue";
import { getDistributionRecordlist, getReissuing, getSyncResult } from "api/apis.js";
export default {
  components: {
    Pagination,
    RcDetail
  },
  setup() {
    const pageRef = ref(null);
    const store = useStore();
    const state = reactive({
      form: {
        txt_distribution_request_no: null,
        dateRange: [],
        status: 0,
        page: 1,
        limit: 10
      },
      table: {
        total: 0,
        data: [],
        loading: false
      },
      detail: {
        data: {},
        show: false
      },
      statusOption: [
        { id: 0, name: "全部" },
        { id: 1, name: "异常" },
        { id: 2, name: "发放中" },
        { id: 4, name: "已发放" },
        { id: 3, name: "发放失败" }
      ],
      operateInfo: computed(() => store.getters.operateInfo)
    })

    onMounted(() => {
      getList()
    })

    // 查看详情
    const handleDetail = row => {
      state.detail.data = row;
      state.detail.show = true;
    };

    // 状态刷新
    const handleReload = row => {
      getSyncResult({
          t_distribution_request_id: row.t_distribution_request_id
        }).then(response => {
          if (response.code == 200) {
            ElMessage.success("刷新成功");
            getList();
          } else {
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        })
    };

    // 再次发放
    const handleReissue = row => {
      ElMessageBox.confirm("费用发放后不可撤销，请确认是否发放？", "提示", {
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(() => {
        getReissuing({
          t_distribution_request_id: row.t_distribution_request_id
        }).then(response => {
          if (response.code == 200) {
            ElMessage.success("发放成功");
            getList();
          } else {
            ElMessage.error("发放失败" + response.msg);
          }
        }).catch(e => {
          console.error(e);
        })
      }).catch(() => {})
    };

    // 获取列表
    const getList = () => {
      state.table.loading = true;
      state.table.data = [];
      let param = {
        txt_distribution_request_no: state.form.txt_distribution_request_no,
        dt_from: (state.form.dateRange || []).length == 0 ? null : state.form.dateRange[0],
        dt_end: (state.form.dateRange || []).length == 0 ? null : state.form.dateRange[1],
        txt_request_status: state.form.status,
        pages: state.form.page,
        pageSize: state.form.limit
      };
      let keys = Object.keys(param);
      for (let i = 0; i < keys.length; i++) {
        if ((param[keys[i]] || "") == "") {
          delete param[keys[i]];
        }
      }

      getDistributionRecordlist(param).then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.table.data = response.data;
            state.table.total = response.total;
          } else {
            state.table.data = [];
            state.table.total = 0;
          }
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.loading = false;
      })
    };

    // 重置表单，重新搜索
    const reset = () => {
      state.form = {
        txt_distribution_request_no: null,
        dateRange: [],
        status: 0,
        page: 1,
        limit: 10
      };
      pageRef.value.pagination.pageSize = 10;
      pageRef.value.pagination.currentPage = 1;
      getList();
    }

    return {
      ...toRefs(state),
      getList,
      handleDetail,
      handleReissue,
      handleReload,
      pageRef,
      reset
    }
  }
}
</script>

<style lang="scss" scoped>
.release-record-index {
  .text-red {
    color: #ff0000;
  }

  .el-table {
    height: calc(100% - 100px);
  }
}
</style>
