<template>
<el-dialog v-model="visible" title="选择需要发放的申请" :before-close="close" custom-class="ex-release-dialog">
  <div class="exr-title row-center-end">
    （
    <span class="row-center-start">项目：
      <template v-if="titles.proName.length<14">{{ titles.proName }}</template>
      <el-popover v-else placement="top-start" trigger="hover" :content="titles.proName">
        <template #reference>
          <div>{{ titles.proName.slice(0, 14) + "..." }}</div>
        </template>
      </el-popover>
    </span>
    <span>受试者筛选号：{{ titles.txt_filter_number }}</span>
    <span>审核日期：{{ titles.date }}</span>
    ）
  </div>
  <el-table ref="exrTable" :data="table.data" v-loading="table.loading" header-cell-class-name="table-cell">
    <el-table-column type="selection" width="55" align="center" :selectable="canSelect" />
    <el-table-column prop="txt_project_name" label="所属项目" align="center" show-overflow-tooltip />
    <el-table-column prop="txt_filter_number" label="受试者筛选号" align="center" show-overflow-tooltip />
    <el-table-column prop="txt_bankcard_number" label="银行卡号" align="center">
      <template #default="scope">
        {{ scope.row.txt_bankcard_number||"- -" }}
      </template>
    </el-table-column>
    <el-table-column prop="dec_compensation_amount" label="费用金额（元）" align="center">
      <template #default="scope">
        {{ scope.row.dec_compensation_amount || "- -" }}
      </template>
    </el-table-column>
    <el-table-column prop="dt_sponsor_verify" label="审核通过时间" align="center">
      <template #default="scope">{{scope.row.dt_sponsor_verify||"- -"}}</template>
    </el-table-column>
  </el-table>
  <div class="exrd-load-comp" @click="getDetail(1)" v-if="table.total!=table.data.length">{{ table.loadTest }}</div>
  <template #footer>
    <div class="dialog-footer">
      <div class="exr-btn-summary"><span>{{ summary.total }}</span>个申请，共 <span>{{summary.amount}}</span> 元</div>
      <el-button type="primary" :disabled="btnDis" @click="goRelease()">下一步</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 详情 - 发放记录/费用管理
import { computed, onMounted, reactive, ref, toRefs } from "vue";
import { getCompensationApplication, goExpenseRelease } from "api/apis.js";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  props: {
    propData: { type: Object },
  },
  setup(prop, ctx) {
    const exrTable = ref(null);
    const state = reactive({
      visible: true,
      checkboxChecked: false,
      table: {
        loadTest: "加载更多",
        page: 1,
        data: [],
        loading: false,
        total: 0
      },
      titles: {
        proName: computed(() => prop.propData.proName ? prop.propData.proName : "- -"),
        txt_filter_number: computed(() => prop.propData.txt_filter_number ? prop.propData.txt_filter_number : "- -"),
        date: computed(() => {
          if ((prop.propData.dateRange||[]).length == 0) {
            return "- -";
          } else {
            return prop.propData.dateRange[0] + " - " + prop.propData.dateRange[1];
          }
        }),
      },
      tableChoosed: computed(() => exrTable.value ? exrTable.value.getSelectionRows() : []),
      summary: {
        amount: computed(() => {
          let result = state.tableChoosed.length == 0 ? 0.00 : state.tableChoosed.reduce((a, b) => a + parseFloat(b.dec_compensation_amount || 0.00), 0.00);
          return result.toFixed(2)
          // state.tableChoosed.length == 0 ? 0.00 : state.tableChoosed.reduce((a, b) => a + parseFloat(b.dec_compensation_amount || 0.00), 0.00)
        }),
        total: computed(() => state.tableChoosed.length == 0 ? 0 : state.tableChoosed.length)
      },
      btnDis: computed(() => {
        if (exrTable.value) {
          if (exrTable.value.getSelectionRows().length > 0) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      })
    })

    onMounted(() => {
      getDetail(0);
    })

    // 费用发放
    const goRelease = () => {
      ElMessageBox.confirm("费用发放后不可撤销，请确认是否发放？", "提示", {
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(() => {
        state.table.loading = true;
        goExpenseRelease({
          application_ids: state.tableChoosed.map(t => t.t_compensation_application_id).join(","),
          bank_type: "CMB"
        }).then(response => {
          if (response.code == 200) {
            ElMessage.success("发放成功！");
            close(true);
          } else {
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.loadding = false;
        })
      })
    };

    // 表格 是否可选
    const canSelect = row => {
      if (row.txt_bankcard_number) {
        return true;
      } else {
        return false;
      }
    };

    // 获取详情
    const getDetail = plus => {
      state.table.loading = true;
      state.table.page = plus == 0 ? 1 : state.table.page + plus;
      if (plus == 0) state.table.data = [];
      let param = {
        t_project_id: prop.propData.t_project_id,
        dt_from: (prop.propData.dateRange || []).length == 0 ? null : prop.propData.dateRange[0],
        dt_end: (prop.propData.dateRange || []).length == 0 ? null : prop.propData.dateRange[1],
        txt_filter_number: prop.propData.txt_filter_number,
        pages: state.table.page,
        pageSize: 10
      };
      let keys = Object.keys(param);
      for (let i = 0; i < keys.length; i++) {
        if ((param[keys[i]] || "") == "") {
          delete param[keys[i]];
        }
      }
      getCompensationApplication(param).then(response => {
        if (response.code == 200) {
          state.table.data = state.table.data.concat(response.data);
          state.table.total = response.total;
          if ((response.data || []).length == 0) {
            state.table.loadTest = "没有更多了";
          } else {
            state.table.loadTest = "加载更多";
          }
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.loading = false;
      })
    }

    const close = (type) => {
      ctx.emit("close", type);
    };

    return {
      ...toRefs(state),
      close,
      getDetail,
      canSelect,
      exrTable,
      goRelease
    }
  }
}
</script>

<style lang="scss">
.ex-release-dialog {
  margin: 0;
  height: calc(100% - 16vh);

  .el-dialog__body {
    padding-top: 14px;
    padding-bottom: 10px;
    box-sizing: border-box;
    height: calc(100% - 130px);

    .exr-title {
      color: #939494;
      margin-bottom: 4px;
      text-align: right;

      span {
        margin: 0 10px;
      }
    }
  }

  p {
    margin: 0;
  }

  .text-red {
    font-weight: bold;
    color: #ff0000;
  }

  .exrd-load-comp {
    font-weight: bold;
    color: #e6a23c;
    cursor: pointer;
    text-align: center;
    margin: 8px 0;
    user-select: none;
    text-decoration: underline;
  }

  .el-dialog__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .dialog-footer {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .exr-btn-summary {
        span {
          color: #e7a988;
          font-weight: bold;
          margin: 0 6px;
        }
      }
    }
  }
}
</style>
